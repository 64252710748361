$(document).ready(() => {

    $('.constructor_part_parameter_value.active').each((i, element) => {
        $(`#selected_${$(element).data('parameterKey')}`).text($(element).data('title'))
    })

    $('.constructor_part_parameter_value').on('click', e => {
        e.preventDefault()

        let target = $(e.currentTarget)
        if (!target.hasClass('selection-slider__item')) {
            target = target.parent('a')
        }

        const parameterKey = target.data('parameterKey')

        $(`.value_with_${parameterKey}`).removeClass('active')
        target.addClass('active')

        $(`#selected_${parameterKey}`).text(target.data('title'))

        let selectedValues = []
        $('.constructor_part_parameter_value.active').each((i, element) => {
            selectedValues.push($(element).data('key'))
        })

        $.ajax({
            type: "POST",
            url: $('#constructor_route').data('route'),
            data: {
                values: selectedValues
            },
            dataType: 'json',

            success: function (response) {
                $('#product_render').attr('href', response.render)
                $('#product_render_img').attr('src', response.render)
                $('#order_modal_img').attr('src', response.renderSize.scaleInSquires)
                $('#price_value').text(response.price)
            },
            error: (xhr) => {
                notifyError();
            }
        })
    })
})
