$(document).ready(function () {

    $('#contact_form').on('submit', (e) => {
        e.preventDefault()

        const form = $(e.target)

        $.ajax({
            type: "POST",
            url: form.data('action'),
            data: {
                name: $('input[name="name__contacts_field"]').val(),
                email: $('input[name="email__contacts_field"]').val(),
                message: $('input[name="message__contacts_field"]').val(),
            },
            dataType: 'json',

            success: response => {
                form.trigger('reset')

                notifySuccess(response.message)
            },
            error: xhr => {
                if (xhr.status === 422) {
                    $.each(xhr.responseJSON.errors, (key, value) => {
                        $(`#${key}__contacts_field`).closest('.form__line').addClass('invalid')
                    });
                } else {
                    notifyError();
                }
            }
        })
    })

});
