$(document).ready(() => {
    $(".lang-toggler__option").on('click', e => {
        e.preventDefault()

        const toggler = $(e.target)

        $.ajax({
            type: 'POST',
            url: toggler.data('route'),
            data: {
                code: toggler.data('language')
            },
            dataType: 'json',
            success: response => {
                location.reload()
            },
            error: (xhr, status, err) => {
                notifyError();
            }
        })
    });
});

const toggleLanguage = (toggler) => {

}
