$(document).ready(function () {

    let data = {
        filter: {},
        category_id: null
    };

    /* mobile */
    $('.catalog-filter__checkbox').on('change', (e) => {
        $('.catalog-filter__checkbox:checked').each((i, item) => {
            data.filter[`${$(item).data('filterId')}`] = $(item).val()
        })

        data.category_id = $('#catalog_container').data('categoryId')

        sendFilter(data)
    })

    /* web */
    $(document).on('change', '.catalog-filter__select', (e) => {
        $('.catalog-filter__select').each((i, item) => {
            data.filter[`${$(item).data('filterId')}`] = $(item).val()
        })

        data.category_id = $('#catalog_container').data('categoryId')

        sendFilter(data)
    })
})

sendFilter = (data) => {
    $.ajax({
        type: "POST",
        url: $('#filter_route').data('action'),
        data: data,
        dataType: 'json',

        success: function (response) {
            $('#catalog__products-section').html(response.html)
        },
        error: (xhr) => {
            if (xhr.status === 422) {
                $.each(xhr.responseJSON.errors, (key, value) => {
                    $(`.${key}__field`).parent().addClass('invalid')
                });
            } else {
                notifyError();
            }
        }
    }).done(() => {
        $('.catalog__product-slider').slick({
            dots: true,
            infinite: true,
            fade: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
        });
    })
}
