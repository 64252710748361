$(document).ready(function () {

    $('.part_parameter').on('click', e => {
        e.preventDefault()

        let key = $(e.target).data('key');

        $(`.${key}`).removeClass('active')

        $(e.target).addClass('active')
        $(e.target).parent('a').addClass('active')

        console.log(getParameters())
    })

    $('#order_form').on('submit', function (e) {
        e.preventDefault()

        sendOrderForm($(this))
    })

    $('.product__button').on('click', e => {
        e.preventDefault()

        $('.modal__success').removeClass('active')
    })

    $('input').on('focus', (e) => {
        $(e.target).closest('.form__line').removeClass('invalid')
    })
});

function getParameters() {
    let data = []

    $('.constructor_part_parameter_value.active').each((key, item) => {
        data.push($(item).data('key'))
    })

    return data
}

function sendOrderForm(form) {
    let data = {
        name: $('input[name="name__order_field"]').val(),
        phone: $('input[name="phone__order_field"]').val(),
        email: $('input[name="email__order_field"]').val(),
        message: $('input[name="message__order_field"]').val(),
        product_id: $('input[name="product_id"]').val(),
        price: $('#price_value').html(),
        image: $('.product__img-wrap').attr('href'),
        parameters: getParameters()
    }

    $.ajax({
        type: "POST",
        url: form.data('action'),
        data: data,
        dataType: 'json',

        success: response => {
            form.trigger('reset');

            $('.modal__success').addClass('active');
        },
        error: xhr => {
            if (xhr.status === 422) {
                $.each(xhr.responseJSON.errors, (key, value) => {
                    $(`#${key}__order_field`).closest('.form__line').addClass('invalid')
                });
            } else {
                notifyError();
            }
        }
    })
}
