window.$ = window.jQuery = require('jquery')

window.toastr = require("toastr/toastr");

require('./theme/slick.min')
require('./theme/jquery.lazy.min')
require('./theme/jquery.fancybox.min')
require('./theme/jquery.nice-select.min')
require('./theme/script')
require('./theme/filter')
require('./theme/constructor')
require('./modal');
require('./contactsForm');
require('./orderForm');
require('./language');

window.number_format = (number, decimals, dec_point, thousands_sep) => {

    var i, j, kw, kd, km;

    // input sanitation & defaults
    if( isNaN(decimals = Math.abs(decimals)) ){
        decimals = 2;
    }
    if( dec_point === undefined ){
        dec_point = ",";
    }
    if( thousands_sep === undefined ){
        thousands_sep = ".";
    }

    i = parseInt(number = (+number || 0).toFixed(decimals)) + "";

    if( (j = i.length) > 3 ){
        j = j % 3;
    } else{
        j = 0;
    }

    km = (j ? i.substr(0, j) + thousands_sep : "");
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
    kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

    return km + kw + kd;
}

window.prettyPrice = (price) => {
    return number_format(price, 2, '.', ' ')
}

$(document).ready(() => {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    window.notifySuccess = (message, title) => {
        console.log(message)
        toastr.success(message, title, {timeOut: 1500});
    };

    window.notifyWarning = (message, title) => {
        console.log(message)
        toastr.warning(message, title, {timeOut: 1500});
    };

    window.notifyError = (message = 'Что-то пошло не так :(', title) => {
        console.log(message)
        toastr.error(message, title, {timeOut: 1500});
    };

    $("#meta_form").on('submit', e => {
        e.preventDefault()

        const form = $(e.target)

        $.ajax({
            type: 'POST',
            url: form.data('action'),
            data: form.serialize(),
            dataType: 'json',
            success: response => {
                notifySuccess('обновлено');
            },
            error: (xhr, status, err) => {
                notifyError('обновление не удалось');
            }
        })
    });

})




